body {
    min-height: 100%;
    width: 100%;
    margin: 0;
    background-color: #F4F7FC;
    font-family: 'Rubik', sans-serif;
}

#root {
    width: 100%;
    height: 100%;
}